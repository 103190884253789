body .digo-card.filters-card {
  border: none;
  padding: 0;
}

input.ng-touched.ng-invalid {
  border-color: red;
}

table.wrapper-prepaid-list {
  border-collapse:separate;
  border-spacing: 0 7px;
}

ul.list-header-prepaid{
  display: flex;
  li{
    margin-right: 10px;
  }
  li:last-child{
    margin-right: 0;
  }
}

.wrapper-prepaid-list{
  //max-width: 1024px;
  width: 100%;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}

table.wrapper-prepaid-list tbody:before {
  content: "-";
  display: block;
  line-height: 0.05em;
  color: transparent;
}


.pagination-head{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  ul{
    list-style: none !important;
    display: flex;
    margin-bottom: 0;
    li{
      margin-left: 16px;
      height: 2em;
      display: flex;
      justify-content: center;
    }
    li:first-child{
      margin-left: 0;
    }
  }
}
.header-table-postpaid th {
  padding: 5px 10px;
  text-align: left;
}
.header-table-postpaid th:first-child {
  padding: 5px 10px 5px 12px;
  text-align: left;
}

.header-table-postpaid{
  background-color: #EEEEEE;
  margin-bottom: 1em !important;
  min-height: 30px;
  //display: flex;
  padding: 2px 16px;
  max-width: 1024px;
  min-width: 1020px;
  width: 100%;
  //align-items: center;
  //justify-content: space-between;
  border-left: 5px solid #EEEEEE;
  .th-large{
    width: 15%;
  }
  .th-middle{
    width: 15%;
  }
  .th-small{
    width: 10%;
  }
}


.tr-list-module{
  background-color: white;
  //height: 100px;
  padding: 0.5em 1em;
  max-width: 1024px;
  min-width: 1020px;
  width: 100%;
  //display: flex;
  //align-items: center;
  //justify-content: space-between;
  margin-bottom: 5px;
  &.c-cancel{
    border-left: 5px solid #F46A6A;
  }
  &.c-complete{
    border-left: 5px solid #50BE87;
  }
  &.h-middle {
    height: 50px;
  }
  .th-large{
    width: 15%;
  }
  .th-middle{
    width: 15%;
  }
  .th-small{
    width: 10%;

  }


}

.tr-list-module td {
  text-align: left;
  padding: 10px;
}


.card-postpaid{
  background-color: white;
  height: 100px;
  padding: 0.5em 1em;
  max-width: 1024px;
  min-width: 1020px;
  width: 100%;
  //display: flex;
  //align-items: center;
  //justify-content: space-between;
  margin-bottom: 5px;
  &.c-cancel{
    border-left: 5px solid #F46A6A;
  }
  &.c-complete{
    border-left: 5px solid #50BE87;
  }
  &.h-middle {
    height: 50px;
  }
  .th-large{
    width: 15%;
  }
  .th-middle{
    width: 15%;
  }
  .th-small{
    width: 10%;

  }
}

.card-postpaid td {
  text-align: center;
}

.dot-pending {
  height: 10px;
  width: 10px;
  background-color: #FEB429;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}
.dot-cancel-red, .dot-blocked {
  height: 10px;
  width: 10px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}
.dot-complete, .dot-unblocked {
  height: 10px;
  width: 10px;
  background-color: #1E8449;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}
.dot-white {
  height: 10px;
  width: 10px;
  background-color: #FFFFFF;
  border-radius: 50%;
  border: 1px solid black;
  display: inline-block;
  margin-right: 5px;
}

.user-block {
  color : red;
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs,1));
  width: 6px;
  height: 5px;
  border: 2px solid;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom: transparent;
  margin-top: -6px;
  margin-left: 20%;
}
.user-block::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 5px;
  border-radius: 1px;
  border: 2px solid transparent;
  box-shadow: 0 0 0 2px;
  left: -3px;
  top: 4px
}

.user-unblock {
  color : green;
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs,1));
  width: 6px;
  height: 3px;
  border: 2px solid;
  border-top-right-radius: 100px;
  border-top-left-radius: 100px;
  border-bottom: transparent;
  margin-top: -9px;
  margin-left: 20%;
}
.user-unblock::after,
.user-unblock::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute
}
.user-unblock::after {
  border: 2px solid transparent;
  box-shadow: 0 0 0 2px;
  width: 8px;
  height: 5px;
  border-radius: 1px;
  left: -2px;
  top: 5px
}
.user-unblock::before {
  width: 2px;
  height: 3px;
  background: currentColor;
  left: -2px;
  top: 1px;
}

.wrapper-card-form{
  width: 100%;
  background: white;
  padding: 32px 51px;
  .content-extract{
    display: flex;
  }
  .body-card {
    padding: 1em 0;
  }
  .footer-card{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    .sub-content-footer{
      width: 50%;
      padding: 1em 0;
    }
  }
}

.digo-button-group {
  padding: 4px;
  position: relative;
}


.digo-button-group input {
  width: auto;
  height: 100%;
  appearance: none;
  outline: none;
  cursor: pointer;
  padding: 5px 15px;
  background: transparent;
  color: black;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  font-size: 14px;
  margin-bottom: 10px;
  transition: all 100ms linear;
  -webkit-transition: all 100ms linear;
  -moz-transition: all 100ms linear;
  -ms-transition: all 100ms linear;
  -o-transition: all 100ms linear;
}

.digo-button-group input:last-child {
  border-right: 1px solid black;
}

.digo-button-group input:checked {
  background-color: black;
  border: 1px solid black;
  color: #fff;
}

.digo-button-group input:before {
  content: attr(label);
  display: inline-block;
  text-align: center;
  width: 100%;
  padding: 4px 8px;
}





@media(max-width: 768px){
  .wrapper-card-form{

    .footer-card{
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      width: 100%;
      .sub-content-footer{
        width: 100%;
      }
    }
  }
}
.c-cancel, .c-blocked{
  border-left: 5px solid #F46A6A;
}
.c-complete, .c-unblocked{
  border-left: 5px solid #50BE87;
}
.c-pending-row {
  border-left: 5px solid #3f91cf;
}

.line-tabs{
  margin-top: 45px;
  width: 100%;
  border-bottom: 1px dashed rgb(210, 210, 210);
  display: flex;
  justify-content: center;
}

.second-line-tabs {
  border-bottom: 2px solid rgb(0, 0, 0);
  width: 250px;
  position: absolute;
  margin-top: -1px;
  margin-left: 10px;
}

.inner-line-tabs {
  position: absolute;
  margin-top: -23px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 270px;
  //background: chartreuse;
}

.dot-form {
  width: 47px;
  height: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  font-size: 22px;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  &.active{
      background: orange;
      color: #fff;
  }
  &.complete{
      background: black;
      color: #fff;
  }
  &.disabled{
      background: #E2E2E2;
      color: #fff;
  }
  &:hover{
      cursor: pointer;
  }
}

.inner-content-form {
  max-width: 1154px;
  margin: 0 auto;
  padding: 134px 16px;
  &.p-0 {
    padding: 0px;
  }
}


.input-activation-request{
  width: 100%;
  margin-bottom: 5em;
  border-bottom: 1px solid #d9d9d9;;
  padding: 4px 0 0 0;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 11px !important;
}
.wrapper-card-form{
  width: 100%;
  background: white;
  padding: 32px 51px;
  .content-extract{
      display: flex;
  }
  .body-card {
      padding: 1em 0;
  }
  .footer-card{
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      width: 100%;
      .sub-content-footer{
          width: 50%;
          padding: 1em 0;
      }
  }
}

.card-permission {
  border: 1px solid lightgray;
  padding: 15px;
  width: 100%;
  margin-bottom: 15px;
  .footer-card-permision {
      display: flex;
      justify-content: space-between;
      .icon {
          font-size: 22px;
      }
      i:hover{
          cursor: pointer;
      }
  }
}


.m-b-30 {
  margin-bottom: 30px;
}

.error-list {
    list-style-type: disc;
    padding-left: 17px;
    &> li {
        margin-bottom: 10px;
    }
}
