/* You can add global styles to this file, and also import other style files */
/*@import '~ng-zorro-antd/ng-zorro-antd.min.css';*/
@import "custom.scss";
@font-face {
    font-family: 'HelveticaNeue';
    src: url("./assets//fonts/Helvetica-neue.ttf") format('ttf'),
  }

$color-primary: #FF7924;
$color-primary-b: #FF7900;
$color-white: #fff;
$color-black: #000;
$color-gray: #a1a1a1;
$color-gray-semi: #707070;
$color-light-gray: #7B7B7B;
$color-success: #50BE87;
$color-pending: #3f91cf;
$color-danger: #F46A6A;
$color-border:  #ebebeb;
$color-purple: #7514BD;


html {
    font-size: 62.5%;
    box-sizing: border-box;
    background-color: #F9F9F9 !important;
    font-family: 'HelveticaNeue', sans-serif;

  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

body {
    font-size: 1.6rem;
    line-height: 2;
    padding: 0;
    background-color: #F9F9F9 !important;
}


ul{
    list-style: none;
    margin: 0;
    padding: 0;
}

.font{
    font-family: 'HelveticaNeue', sans-serif;
    &.s-8{ font-size: 8px;}
    &.s-10{ font-size: 10px;}
    &.s-12{ font-size: 12px;}
    &.s-13{ font-size: 13px;}
    &.s-14{ font-size: 14px;}
    &.s-15{ font-size: 15px;}
    &.s-16{ font-size: 16px;}
    &.s-18{ font-size: 18px;}
    &.s-20{ font-size: 20px;}
    &.s-22{ font-size: 22px;}
    &.s-28{ font-size: 28px;}

    &.c-primary{color: $color-primary}
    &.c-white{color: $color-white}
    &.c-black{color: $color-black}
    &.c-gray{color: $color-gray;}
    &.c-light-gray{ color: $color-light-gray;}
    &.c-success{ color: $color-success;}
    &.c-pending{color : $color-pending}
    &.c-danger{color: $color-danger;}
    &.c-purple-light{color: #A885D8;}

    &.a-center{text-align: center;}

    &.w-ligth{font-weight: 300;}
    &.w-regular{font-weight: 400;}
    &.w-semibold{font-weight: 500;}
    &.w-bold{font-weight: 700;}

    &.s-italic{font-style: italic;}

    &.t-up{text-transform: uppercase;}

    &.no-bottom-small{margin-bottom: -.5em; line-height: 0;}
    &.no-bottom-medium{margin-bottom: 0em; line-height: 0;}
    &.no-bottom-large{margin-bottom: 0.3em; line-height: 0;}
    &.no-bottom-extra-large{margin-bottom: 1em; line-height: 0;}
    &.title-menu{margin-bottom: 25px;}

    &.link{
        text-decoration: none;
    }
    &.cursor-default {
      cursor: default;
    }
    &.capitalize {
        text-transform: capitalize;
    }
}


.icon{
    &.s-10{ width: 10px; font-size: 10px;}
    &.s-12{ width: 12px;}
    &.s-14{ width: 14px;}
    &.s-16{ width: 16px;}
    &.s-22{ width: 22px;}

    &.size-14 { font-size: 14px;}
    &.size-18 { font-size: 18px;}
    &.size-22 { font-size: 22px;}

    &.c-white{color: $color-white}
    &.c-orange{ color: $color-primary;}
    &.c-default { color: $color-gray-semi; }
    &.c-disabled { color: #E2E2E2; }

    &.drop:hover{cursor: pointer;}

    &.i-rotate{
      transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      -o-transform: rotate(90deg);
    }
}

.margins {
  &.m-r-0{ margin-right: 0px;}
  &.m-r-5{ margin-right: 5px;}
  &.m-r-10{ margin-right: 10px;}
  &.m-r-15{ margin-right: 15px;}
  &.m-r-30{ margin-right: 30px;}

  &.m-b-0{ margin-bottom: 0px;}
  &.m-b-5{ margin-bottom: 5px;}
  &.m-b-10{ margin-bottom: 10px;}
  &.m-b-15{ margin-bottom: 15px;}
  &.m-b-20{ margin-bottom: 20px;}
  &.m-b-30{ margin-bottom: 30px;}
  &.m-b-40{ margin-bottom: 40px;}
  &.m-b-50{ margin-bottom: 50px;}
  &.m-b-60{ margin-bottom: 50px;}
  &.m-b-70{ margin-bottom: 70px;}

  &.m-t-0{margin-top: 0px;}
  &.m-t-5{margin-top: 5px;}
  &.m-t-10{margin-top: 10px;}
  &.m-t-15{margin-top: 15px;}
  &.m-t-20{margin-top: 20px;}
  &.m-t-30{margin-top: 30px;}
  &.m-t-40{margin-top: 40px;}
  &.m-t-50{margin-top: 50px;}
  &.m-t-60{margin-top: 60px;}
  &.m-t-70{margin-top: 70px;}

  &.m-t--10 {margin-top: -10px;}

  &.m-l-5 {margin-left: 5px;}
  &.m-l-10 {margin-left: 10px;}
}

//widths
.w-full {
    width: 100%;
}

.padding {
  &.px-0 {padding-left: 0; padding-right: 0;}

  &.py-0 {padding-top: 0; padding-bottom: 0;}

  &.py-10 {padding-top: 10px !important; padding-bottom: 10px !important;}
}

.d-flex {
  display: flex!important;
  &.justify-content-between {
    justify-content: space-between;
  }
  &.justify-content-around {
    justify-content: space-around;
  }
  &.justify-content-evenly {
    justify-content: space-evenly;
  }
  &.justify-content-center {
    justify-content: center;
  }
  &.justify-content-start {
    justify-content: flex-start;
  }
  &.justify-content-end {
    justify-content: flex-end;
  }
}

.space-break {
  white-space: break-spaces!important;
}
.tag{
  box-sizing: border-box;
  margin: 0 8px 0 0;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum","tnum";
  display: inline-block;
  height: auto;
  padding: 0 7px;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  opacity: 1;
  transition: all .3s;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  span.text-first{
    margin-right: 8px;
  }
  &.success{
    background:$color-success;
    color: white;
  }
  &.purple{
    background: $color-purple;
    color: white;
    padding: 5px 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 27px;
    width: fit-content;
    margin: 0 10px 10px 0;
    // &:last-child{
    //   margin: 0 0px 10px 0;
    // }
  }
  &.responsive {
    height: auto !important;
    white-space: break-spaces;
  }
  &.orange{
    background: $color-primary;
    color: white;
    padding: 5px 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 27px;
    width: fit-content;
    margin: 0 10px 10px 0;
    // &:last-child{
    //   margin: 0 0px 10px 0;
    // }
  }
  &.black{
    background: $color-black;
    color: white;
    padding: 5px 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 27px;
    width: fit-content;
    margin: 0 10px 10px 0;
    // &:last-child{
    //   margin: 0 0px 10px 0;
    // }
  }
  &.closable{
    i{&:hover{
        cursor: pointer;
    }}
  }
}

.dot{
    width: 8px;
    min-width: 8px;
    height: 8px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    &.c-amena{ background: green;}
    &.c-orange{ background: $color-primary;}
    &.c-simyo{ background: #5F5F5F;}
    &.c-danger{background:crimson}

    &.c-cancel{background: $color-danger}
    &.c-complete{background: $color-success}
    &.c-pending-row{background: $color-pending}
}


.letters {
  font-size: 18px;
  width: 24px;
  text-transform: uppercase;
  font-weight: 500;
  margin-right: 10px;
  &:last-child {
    margin-right: 0;
  }
  &.success{
    color: #55b032;
  }
    &.large {
        width: auto;
    }
}

.diference {
    display: flex;
    align-items: center;

    p {
        margin-bottom: 0;
    }

    &.up {
        color: #55b032;

        i {
            transform: rotate(-90deg);
            -webkit-transform: rotate(-90deg);
            -moz-transform: rotate(-90deg);
            -ms-transform: rotate(-90deg);
            -o-transform: rotate(-90deg);
            margin-right: 5px;
        }
    }

    &.down {
        color: #F46A6A;

        i {
            transform: rotate(90deg);
            -webkit-transform: rotate(90deg);
            -moz-transform: rotate(90deg);
            -ms-transform: rotate(90deg);
            -o-transform: rotate(90deg);
            margin-right: 5px;
        }
    }
}
// ARROW LINK & DROPDOWN

.arrow-active{
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transition: transform .2s ease-in-out;
  -webkit-transition: transform .2s ease-in-out;
  -moz-transition: transform .2s ease-in-out;
  -ms-transition: transform .2s ease-in-out;
  -o-transition: transform .2s ease-in-out;
}
.arrow-no-active{
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transition: transform .2s ease-in-out;
  -webkit-transition: transform .2s ease-in-out;
  -moz-transition: transform .2s ease-in-out;
  -ms-transition: transform .2s ease-in-out;
  -o-transition: transform .2s ease-in-out;
}



.container-max{
    width: 100%;
    padding: 148px 0 48px 0;
}

.inner-content-max{
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 1em;
}

.head-content-max{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
    //min-height: 84px;
    align-items: center;
}

.title-content-max{
    margin-bottom: 22px;
}

.flex-content{
    display: flex !important;
    align-items: center;
    &.f-start{
        justify-content: flex-start;
    }
    &.f-end{
        justify-content: flex-end;
    }
    &.f-between{
        justify-content: space-between;
    }
    &.a-column{
        flex-direction: column;
    }
    &.a-center{
        justify-content: center;
    }
    &.a-base{
      align-items: baseline;
    }
    &.a-wrap {
      align-items: baseline;
      flex-wrap: wrap;
    }
    &.f-evenly{
      justify-content: space-evenly;
    }
    &.ai-center {
      align-items: center;
    }
    @media only screen and (max-width: 360px) {
      &.f-direction-column {
        flex-direction: column;
      }
    }
    @media only screen and (max-width: 415px) {
      &.f-start-xs {
        justify-content: flex-start;
      }
    }
    @media only screen and (max-width: 768px) {
      &.a-wrap-md {
        align-items: baseline;
        flex-wrap: wrap;
      }
    }
}

@media only screen and (max-width: 510px) {
  .xs-no-margins {
    margin: 0px !important;
  }
}

.digo-card{
    background: $color-white;
    border: 1px solid lightgrey;
    width: 100%;
    margin-bottom: 24px;
    .digo-card-header{
        padding: 1em;
    }
    .digo-card-body{
        padding: 1em;
    }
    .digo-card-footer{
        padding: 1em;
    }
    &.form-card{
      padding: 30px;
    }
    &.card-gray{
      background: #EEEEEE;
    }
    &.big-form-card {
      padding: 50px;
    }
    &.big-card {
      padding: 50px 70px;
    }
    &.tools-card {
      padding: 20px 30px;
      margin-bottom: 10px;
      .header-tools-card {
        display: flex;
        justify-content: flex-start;
        img{
          margin-right: 30px;
        }
      }
      .body-tools-card {
        margin-top: 20px;
        ul.default{
          li{
            margin-bottom: 6px;
          }
        }
        ul.sublist{
          li{
            margin-left: 15px;
          }
        }
      }
    }
    &.custom-space{
      margin-bottom: 5px !important;
      padding: 20px 20px 4px 20px !important;
        &.mb-30 {
            margin-bottom: 30px !important;
        }
    }
}

.card-link-report{
  display: flex;
  border: 1px solid black;
  padding: 8px;
  margin-bottom: 5px;
  &.inline-row{
    min-height: 2.5em;
    align-items: center;
    justify-content: space-between;
  }
  &.inline-column{
    height: 7em;
    flex-direction: column;
    justify-content: space-between;
    i{
      align-self: flex-end;
    }
  }
  &:hover{
    cursor: pointer;
    background: black;
    color: white;
  }
}

.card-section{
  padding: 28px;
  background: white;
  width: 100%;
  border: 1px solid $color-border;
  .header{
      border-bottom: 1px solid $color-border;
      padding-bottom: 26px;
  }
  .body{
      margin-top: 30px;
  }
  .header-list{
      padding-bottom: 26px;
      display: flex;
      justify-content: space-between;
      align-items: baseline;
  }
}

.card-profile {
  padding: 30px 50px;
  background: white;
  width: 100%;
  border: 1px solid $color-border;
  margin-bottom: 30px;
}

.cards-other-data {
  color: #000;
  border: 1px solid lightgray;
  padding: 30px 17px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  i.icon-type{
      font-size: 36px;
      margin-bottom: 10px;
  }
  i.icon-arrow {
      color: #7B7B7B;
      font-size: 16px;
  }
  &:hover{
      color: #000;
      cursor: pointer;
  }
}

.card-permission{
  background: white;
  color: #000;
  border: 1px solid #E2E2E2;
  padding: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 74px;
  margin-bottom: 15px;
  .font{
    margin-right: 10px;
  }
  &:hover { cursor: pointer; }
  &:active{
    background: black;
    .font {color: $color-primary;}
  }
  &.active{
    background: black;
    .font {color: $color-primary;}
  }
}

.card-permission-column{
  background: white;
  color: #000;
  border: 1px solid #E2E2E2;
  padding: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  min-height: 74px;
  margin-bottom: 15px;
  .body-card-permission-column{
    border-bottom: 1px solid lightgray;
  }
  .footer-card-permision-column {
    padding-top: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .font{
    margin-right: 10px;
  }
  &:hover { cursor: pointer; }
  &:active{
    background: black;
    .font {color: $color-primary;}
  }
}


.bar-head{
  width: 100%;
  background: #EEEEEE;
  height: 35px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 18px;
  margin-bottom: 7px;
  .title-head{
    margin-right: 16px
  }
}

.tag-info {
  display: flex;
  align-items: center;
  border: 1px solid blue;
  padding: 2px 6px;
  .icon-tag-info {
    margin-right: 10px;
    font-size: 10px;
  }
}

//***********+ digo-input-material

fieldset {
    margin: 0 0 3rem;
    padding: 0;
    border: none;
  }

  .form-radio,
  .form-group {
    position: relative;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    &.form-selected {
      margin-top: 2rem;
    }
  }

  .form-inline > .form-group,
  .form-inline > .btn {
    display: inline-block;
    margin-bottom: 0;
  }

  .form-help {
    margin-top: 0.125rem;
    margin-left: 0.125rem;
    color: #b3b3b3;
    font-size: 0.8rem;
  }

  .checkbox .form-help,
  .form-radio .form-help,
  .form-group .form-help {
    position: absolute;
    width: 100%;
  }

  .checkbox .form-help {
    position: relative;
    margin-bottom: 1rem;
  }

  .form-radio .form-help {
    padding-top: 0.25rem;
    margin-top: -1rem;
  }

  .form-group input {
    height: 3.2rem;
  }

  .form-group textarea {
    resize: none;
  }

  .form-group select {
    width: 100%;
    font-size: 14px !important;
    height: 1.95rem;
    padding: 0.125rem 0.125rem 0.0625rem;
    background: none;
    border: none;
    line-height: 1.6;
    box-shadow: none;
  }

  .form-group .control-label {
    position: absolute;
    top: 0.25rem;
    pointer-events: none;
    padding-left: 0.125rem;
    z-index: 1;
    color: #000;
    font-size: 14px;
    font-weight: normal;
    -webkit-transition: all 0.28s ease;
    transition: all 0.28s ease;
  }

  .form-group .bar {
    position: relative;
    border-bottom: 0.0625rem solid #ddd;
    display: block;
  }

  // input.ng-touched.ng-invalid:required + label + .bar {
  //   position: relative;
  //   border-bottom: 0.0625rem solid red !important;
  //   display: block;
  // }

  // nz-select.ng-touched.ng-invalid + .bar {
  //   border-bottom: 0.0625rem solid red !important;
  // }

  .error-form {
    color: red;
  }

  .select-prepaid {
    width: 100%;
  }

  .form-group .bar::before {
    content: "";
    height: 0.125rem;
    width: 0;
    left: 50%;
    bottom: -0.0625rem;
    position: absolute;
    background: #000;
    -webkit-transition: left 0.28s ease, width 0.28s ease;
    transition: left 0.28s ease, width 0.28s ease;
    z-index: 2;
  }

  .form-group input,
  .form-group textarea {
    display: block;
    background: none;
    padding: 0.125rem 0.125rem 0.0625rem;
    font-size: 14px !important;
    border-width: 0;
    border-color: transparent;
    line-height: 1.9;
    width: 100%;
    color: transparent;
    -webkit-transition: all 0.28s ease;
    transition: all 0.28s ease;
    box-shadow: none;
  }

  .form-group input[type="file"] {
    line-height: 1;
  }

  .form-group input[type="file"] ~ .bar {
    display: none;
  }

  .form-group select,
  .form-group input:focus,
  .form-group input:valid,
  .form-group input.form-file,
  .form-group input.has-value,
  .form-group textarea:focus,
  .form-group textarea:valid,
  .form-group textarea.form-file,
  .form-group textarea.has-value,
  .form-group input[readonly] {
    color: #333;
  }

  .form-group select ~ .control-label,
  .form-group input:focus ~ .control-label,
  //.form-group input:valid ~ .control-label,
  // .form-group input.ng-touched ~ .control-label,
  .form-group input[type="date"]:valid ~ .control-label,
  .form-group input[type="time"] ~ .control-label,
  .form-group input.form-file ~ .control-label,
  .form-group input.has-value ~ .control-label,
  .form-group textarea:focus ~ .control-label,
  .form-group textarea:valid ~ .control-label,
  .form-group textarea.form-file ~ .control-label,
  .form-group textarea.has-value ~ .control-label,
  .form-group input[readonly] ~ .control-label {
    font-size: 12px;
    color: black;
    top: -1.5rem;
    left: 0;
    &.left-inherit {
      left: inherit;
    }
  }

  .form-group select:focus,
  .form-group input:focus,
  .form-group textarea:focus {
    outline: none;
  }

  .form-group select:focus ~ .control-label,
  .form-group input:focus ~ .control-label,
  .form-group textarea:focus ~ .control-label {
    color: #000;
  }

  .form-group select:focus ~ .bar::before,
  .form-group input:focus ~ .bar::before,
  .form-group textarea:focus ~ .bar::before {
    width: 100%;
    left: 0;
  }

  .form-group input[readonly]{
    cursor: not-allowed;
  }


  .checkbox label{
    position: relative;
    cursor: pointer;
    padding-left: 2rem;
    text-align: left;
    color: #333;
    display: block;
    margin-top: -10px;
  }

  .checkbox input {
    width: auto;
    opacity: 0.00000001;
    position: absolute;
    left: 0;
  }

  .checkbox {
    margin-top: 15px;
    margin-bottom: 34px;
  }

  .check-pack{
    position: absolute;
    margin-top: -2px;
  }

  .checkbox .helper {
    color: #999;
    position: absolute;
    top: 0;
    left: 0;
    width: 1rem;
    height: 1rem;
    z-index: 0;
    border: 0.125rem solid currentColor;
    border-radius: 0.0625rem;
    -webkit-transition: border-color 0.28s ease;
    transition: border-color 0.28s ease;
  }
  .checkbox .helper::before, .checkbox .helper::after {
    position: absolute;
    height: 0;
    width: 0.2rem;
    background-color: #55b032;
    display: block;
    -webkit-transform-origin: left top;
            transform-origin: left top;
    border-radius: 0.25rem;
    content: '';
    -webkit-transition: opacity 0.28s ease, height 0s linear 0.28s;
    transition: opacity 0.28s ease, height 0s linear 0.28s;
    opacity: 0;
  }
  .checkbox .helper::before {
    top: 0.65rem;
    left: 0.38rem;
    -webkit-transform: rotate(-135deg);
            transform: rotate(-135deg);
  }
  .checkbox .helper::after {
    top: 0.3rem;
    left: 0;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  .checkbox label:hover .helper {
    color: #55b032;
  }
  .checkbox input:checked ~ .helper {
    color: #55b032;
  }
  .checkbox input:checked ~ .helper::after, .checkbox input:checked ~ .helper::before {
    opacity: 1;
    -webkit-transition: height 0.28s ease;
    transition: height 0.28s ease;
  }
  .checkbox input:checked ~ .helper::after {
    height: 0.5rem;
  }
  .checkbox input:checked ~ .helper::before {
    height: 1.2rem;
    -webkit-transition-delay: 0.28s;
            transition-delay: 0.28s;
  }

  .checkbox + .checkbox {
    margin-top: 1rem;
  }


//////////// RADIO GROUP

.digo-radio-group {
	padding: 4px;
	position: relative;
}

.digo-radio-group input {
    width: auto;
    height: 100%;
    appearance: none;
    outline: none;
    cursor: pointer;
    padding: 4px 18px;
    background: transparent;
    color: black;
    border: 1px solid black;
    font-size: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    transition: all 100ms linear;
    -webkit-transition: all 100ms linear;
    -moz-transition: all 100ms linear;
    -ms-transition: all 100ms linear;
    -o-transition: all 100ms linear;
}

.digo-radio-group input:checked {
	background-color: $color-primary-b;
  border: 1px solid $color-primary-b;
	color: #fff;

}

.digo-radio-group input:before {
	content: attr(label);
	display: inline-block;
	text-align: center;
	width: 100%;
    padding: 4px 18px;
}

.ant-switch-checked {
  background-color: #ff7a23 !important;
}

// Radio group buttons

// .digo-button-group {
// 	padding: 4px;
// 	position: relative;
// }

// .digo-button-group input {
//   width: auto;
//   height: 100%;
//   appearance: none;
//   outline: none;
//   cursor: pointer;
//   padding: 8px 18px;
//   background: transparent;
//   color: black;
//   border-top: 1px solid black;
//   border-left: 1px solid black;
//   border-bottom: 1px solid black;
//   font-size: 10px;
//   margin-bottom: 10px;
//   transition: all 100ms linear;
//   -webkit-transition: all 100ms linear;
//   -moz-transition: all 100ms linear;
//   -ms-transition: all 100ms linear;
//   -o-transition: all 100ms linear;
// }

// // .digo-button-group input:last-child {
// //   background: red;
// // }

// .digo-button-group input:checked {
// 	background-color: $color-primary-b;
//   border: 1px solid $color-primary-b;
// 	color: #fff;
// }

// .digo-button-group input:before {
// 	content: attr(label);
// 	display: inline-block;
// 	text-align: center;
// 	width: 100%;
//   padding: 8px 18px;
// }


// ********************************************+******************************** //
// ************************************** ANT ********************************* //
// ********************************************+*******************************//



// ANT SELECT

.ant-select {
  font-size: 14px !important;
  font-weight: 700;
  color: #bfbfbf;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  color: black;
}
nz-select[nzShowSearch]:focus-within.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder { // nzShowSearch
  color: transparent;
}
.ant-select-selection-search-input{
  margin-left: -1rem !important;
}
// TABS

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ff7900 !important;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 1px !important;
}

.ant-tabs-ink-bar {
  background: $color-primary !important;
}

.ant-tabs-nav-operations {
  min-width: 46px;
}

// ANT CHECKBOX

.ant-checkbox-inner{
    border-color: #000 !important;
    background: transparent;
}
.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #000;
    border-color: #000;
}
.ant-checkbox-focus, .ant-checkbox-hover, .ant-checkbox-checked::after{
    border-color: #000 !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #ff7900 !important;
    border-color: #000;
}


// modal

.ant-modal-body {
  background: #F9F9F9 !important;
}




// BUTTONS

[nz-button]{

    /* margin-right: 1em; */
    span{
        font-size: 14px;
    }
    &.c-primary{
        background: white !important;
        color: black !important;
        border-color: black !important;
        &:hover, :active, :focus, :target, :visited, :focus-visible, :focus-within {
            background-color: black !important;
            color: white !important;
            border-color: black !important;
        }
        &:disabled {
            opacity: 0.5;
            &:hover, :active, :focus, :target, :visited, :focus-visible, :focus-within {
                background-color: white !important;
                color: black !important;
                border-color: black !important;
                opacity: 0.5;
            }
        }
    }
    &.c-dark{
      background: black !important;
      color: rgb(255, 255, 255) !important;
      border-color: black !important;
      &:hover, :active, :focus, :target, :visited, :focus-visible, :focus-within {
          background-color: black !important;
          color: white !important;
          border-color: black !important;
      }
      &:disabled {
          opacity: 0.5;
          &:hover, :active, :focus, :target, :visited, :focus-visible, :focus-within {
              background: black !important;
              color: rgb(255, 255, 255) !important;
              border-color: black !important;
              opacity: 0.5;
          }
      }
    }

    &.c-dark-orange{
      background: white !important;
      color: black !important;
      border-color: black !important;
      &:hover, :active, :focus, :target, :visited, :focus-visible, :focus-within {
          background-color: black !important;
          color: $color-primary !important;
          border-color: black !important;
        }
      &.active {
        background-color: black !important;
        color: $color-primary !important;
        border-color: black !important;
      }
    }

    &.c-text{
      background: transparent!important;
      color: black!important;
      border: 1px solid black !important;
      &:hover, :active, :focus, :target, :visited, :focus-visible, :focus-within {
          background-color: none !important;
          color: black!important;
      }
    }

    &.c-text-delete{
      background: transparent!important;
      color: gray !important;
      border: none;
      text-decoration: underline;
      width: auto !important;
      &:hover, :active, :focus, :target, :visited, :focus-visible, :focus-within {
          background-color: red !important;
          color: white !important;
      }
    }

    &.size-extra-large {
      padding: 20px;
      height: 64px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.size-extra-large-2 {
      padding: 20px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.size-large-2 {
        padding: 10px 50px;
        height: 34px;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 202px;
    }

    &.size-large{
        padding: 10px 50px;
        height: 34px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &.size-medium{
      padding: 10px 20px;
      height: 34px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &.size-xxs{
        width: 15px !important;
        height: 15px !important;
        border-radius: 50px;
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        -ms-border-radius: 50px;
        -o-border-radius: 50px;
        i{
            font-size: 7px;
        }
    }
    &.size-md{
      width: 32px !important;
      height: 32px !important;
      border-radius: 50px;
      -webkit-border-radius: 50px;
      -moz-border-radius: 50px;
      -ms-border-radius: 50px;
      -o-border-radius: 50px;
      i{
          font-size: 15px;
      }
  }
}


// BADGE

.ant-badge-status-processing {
  position: relative;
  background-color: #ff8181 !important;
}

.ant-badge-status-processing::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #ff8181 !important;
  border-radius: 50%;
  animation: antStatusProcessing 1.2s infinite ease-in-out;
  content: '';
}

.cursor-pointer {
  cursor: pointer;
}


.label-for-nz-select {
  position: relative;
  height: 0px;
  bottom: 6px;
  font-size: 12px;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  border: 1px solid transparent;
  padding: 1rem 2rem;
}

.classification-center {
    min-width: 24px;
    text-align: center;
}

.modal-black {
    height: 100vh !important;
    max-height: none !important;
    max-width: none !important;
    opacity: .9;

    .ant-modal-body {
        background: white !important;
        height: 100vh !important;
    }

    .ant-modal-close {
        color: black !important;
        right: 110px;
        top: 30px;
    }
}

.ant-divider-vertical {
  &.divider-vertical {
    height: 4em;
  }
  @media (max-width: 360px) {
    &.divider-vertical {
      display: none;
    }
  }
}

.document-modal{
  &.ant-modal{
    width: 100%;
    max-width: 1720px;
  }
  .ant-modal-close {
    color: $color-white;
    top: 3px;
    right: 3px;
    &:hover {
      color:$color-gray-semi;
    }
  }
  .ant-modal-header{
      background-color: $color-black;
      .header-modal {
        position: relative;
        .title{
          position: absolute;
          left: 0;
        }
        .btn-step {
          background: transparent;
          border: none;
          outline: none;
          box-shadow: none;

          &.left {
            transform: rotate(180deg);
          }
        }
        .close {
          position: absolute;
          right: 0;
          button {
            background-color: transparent;
            border: none;
          }
        }
      }
  }
  .ant-modal-body {
    padding: 0;
    .header-content {
      display: flex;
      padding: 24px;
      justify-content: center;
      align-items: flex-start;
      gap: 32px;
      align-self: stretch;
      border: 1px solid  #E2E2E2;
      background:  #FFF;
      .header {
        display: flex;
        width: 1400px;
        padding: 0px 16px;
        align-items: center;
        gap: 32px;
        justify-content: space-between;



      }
    }
    .status {
      display: inline-block;
      width: 8px;
      height: 8px;
      background-color: $color-gray-semi;
      border-radius: 50%;
      margin-right: 10px;
      &.c-green {
        background-color: var(--accent-green-01, #55B032);
      }
      &.c-danger {
        background-color: $color-danger;
      }
    }
    .btn-action {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 24px;
      &.gap-0 {
        gap: 0;
      }
      .btn {
        display: flex;
        height: 38px;
        padding: 0px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: none;
        margin-top: 5px;
        &.cancel {
          background: var(--accent-salmon-01, #F46A6A);
        }
        &.success {
          background: var(--accent-green-01, #55B032);
        }
        &.info {
          background-color: $color-pending;
        }
      }
      .btn-badge {
        .btn {
          margin-top: 0;
        }
      }
    }
    .card-more-info-document {
      display: flex;
      padding: 32px 16px;
      flex-direction: column;
      align-items: center;
      gap: 32px;
      .card-content{
        max-width: 1400px;
        width: 100%;
        .info-document {
          display: flex;
          padding: 32px;
          align-items: flex-start;
          align-self: stretch;
          justify-content: space-between;
          flex-wrap: wrap;
          gap: 10px;
          .content-field {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 45%;
            align-self: stretch;
            margin-bottom: 14px;
            &.w-full {
              flex: 100%;
            }
          }
          .line {
            width: 100%;
            height: 1px;
            background-color: #E2E2E2;
            margin-bottom: 24px;
          }
          div:nth-last-child(1), div:nth-last-child(2) {
            margin-bottom: 0;
          }

          &.btn-card{
            background: transparent;
            border: none;
            padding: 0;
            gap: 8px;
            .btn {
              flex: 100%;
              display: flex;
              padding: 15px 20px;
              align-items: center;
              gap: 10px;
              border: 1px solid var(--neutral-neutral-04, #E2E2E2);
              background: var(--neutral-neutral-06, #FFF);
              &.disabled{
                cursor: not-allowed;
                background-color: $color-gray;
                color: $color-light-gray;
                p {
                  color: $color-light-gray;
                }
              }
              &:hover {
                background-color: $color-gray;
                p {
                  color: white;
                }
              }
            }
          }
        }
        .ant-tabs-tab{
          padding-top: 0;
          margin-top: -5px;
          .ant-tabs-tab-btn{
            color: var(--neutral-neutral-01, #000);
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.048px;
          }
        }
        .observations {
          .big-card {
            padding: 32px;
            .btn-add {
              padding: 10px 15px;
              margin-left: 2px;
              font-weight: 700;
            }
            textarea {
              &:disabled {
                color: #515151;
              }
            }
          }
        }

        .title-document {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 16px;
        }

      }
    }
  }
}

@media only screen and (max-width: 1400px) {
  .document-modal {
    .ant-modal-body {
      .card-more-info-document {
        .card-content {
          .info-document {
            &.btn-card {
              .btn {
                flex: 100%;
              }
            }
          }
        }
      }
    }
  }
}

.btn-badge {
  position: relative;

  margin-top: 5px;
  margin-right: 5px;
  .badge {
      position: absolute;
      right: -11px;
      top: -10px;
      z-index: 1;
      .ant-badge-status-dot{
          width: 10px !important;
          height: 10px!important;
      }
  }
}
.generate-report {
  &.disabled {
      opacity: 50%;
  }
}

#pdvsLoader div h5 {
  margin-top: -70px !important;
}
